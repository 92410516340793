<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true" >
  <el-form-item label="合作商">
    <el-input v-model="query.name" placeholder="输入合作商名称"></el-input>
  </el-form-item>
  <el-form-item label="类型">
    <el-select v-model="query.type" placeholder="选择合作商类型" clearable>
      <el-option v-for="(item,i) in typeList" :key="i" :value="item.value" :label="item.label"></el-option>
    </el-select>
  </el-form-item>
   <el-form-item label="状态">
    <el-select v-model="query.status" placeholder="选择" clearable>
      <el-option label="正常" :value="0"></el-option>
      <el-option label="冻结" :value="2"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item>
     <el-button  type="primary" @click="getList(1)">搜索</el-button>
  </el-form-item>
   <el-form-item>
     <el-button v-permission="'addCooperator'" type="primary" @click="add">新增</el-button>
  </el-form-item>
</el-form>
   
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type="{ row }">
        <div>{{ $getLabel(typeList,row.type) }}</div>
        <el-tag v-if="row.status == 2" type="danger" size="small">冻结</el-tag>
      </template>
      <template #handle="{ row }">
        <el-button size="small" type="success" @click="update(row)"
          >编辑</el-button
        >
         <el-button size="small" type="primary" @click="details(row)"
          >详情</el-button
        >
        <el-button v-if="row.status === 0" size="small" type="danger" @click="updateStatus(row,2)"
          >冻结</el-button
        >
         <el-button v-if="row.status === 2"  size="small" type="success" @click="updateStatus(row,0)"
          >解冻</el-button
        >

      </template>
    </auto-table>
    <AddorEdit @success="getList" ref="AddorEdit"></AddorEdit>
  </div>
</template>

<script>
import AddorEdit from "./components/AddorEdit.vue";
import { coopTypeList } from "./components/type.js";
export default {
  components: {
    AddorEdit
  },
  data() {
    return {
      nav: { firstNav: "合作商管理", secondNav: "合作商列表" },
      DataList: [],
     query:{
       name:'',
      type:'',
     },
     typeList:coopTypeList,
      type: {
        cooperator: "合作商",
        property: "物业",
        commerce:'商会'
      },
      Option: [
        { name: "合作商", value: "title" },
        { name: "联系人", value: "name" },
        { name: "联系电话", value: "phone" },
        { name: "类型", value: "type", type: "custom" },
        { name: "城市", value: "city" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    details(row){
      this.$router.push({name:'cooperatorDetails',query:{id:row.id}});
    },
    adminDivisionHandle(row) {
      this.$refs.admindivi.visiable(row.id);
    },
    divisionHandle(row) {
      this.$refs.division.open(row);
    },
    add() {
      this.$refs.AddorEdit.open();
    },
    update(row) {
      this.$refs.AddorEdit.open(row);
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
     updateStatus(row,status){
      this.$axios({
        url: "/user/cooperator/update",
        data: {
          id:row.id,
          status
        },
        method:"post"
      }).then((res) => {
       this.getList(1)
      });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/cooperator/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          title:this.query.name || null,
           status:this.query.status,
          types:this.query.type || 'cooperator,property,commerce',
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>