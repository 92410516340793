<template>
  <!-- 合作商新增，修改 -->
  <div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" :rules="rules" label-width="120px" ref="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="合作商名称" required prop="title">
              <el-input
                v-model="form.title"
                placeholder="合作商名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" required prop="name">
              <el-input v-model="form.name" placeholder="联系人名称"></el-input>
            </el-form-item>
            <el-form-item label="联系人电话" required prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="联系人电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型" required prop="type">
              <el-radio-group v-model="form.type">
                <el-radio :label="item.value" v-for="(item,i) in typeList" :key="i">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="所属合作商" v-if="form.type == 'property'">
              <cooperatorSelect
              :clearable="true"
                :condition="{ type: 'cooperator' }"
                :coopid.sync="coop_id"
                :info="form.map ? form.map.parent.title : ''"
              ></cooperatorSelect>
            </el-form-item>
            <el-form-item label="福利领取方式" required>
              <el-radio-group v-model="form.receive_way">
                <el-radio :label="1">无限制</el-radio>
                <el-radio :label="2">指定人群</el-radio>
              </el-radio-group>
            </el-form-item>
             <el-form-item label="登录账号" prop="login_name" v-if="!form.id">
              <el-input
                v-model="form.login_name"
                placeholder="账号"
              ></el-input>
            </el-form-item>
            <el-form-item label="登录密码" v-if="!form.id">
              <el-input
                v-model="form.login_pwd"
                placeholder="密码"
              ></el-input>
              默认123456
            </el-form-item>
            <el-form-item label="支付宝实名">
              <el-input
                v-model="form.account_name"
                placeholder="支付宝实名"
              ></el-input>
            </el-form-item>
            <el-form-item label="支付宝账号">
              <el-input
                v-model="form.account_ali"
                placeholder="支付宝账号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择地址">
              <addr-select
                @getform="changeMapAddr"
                :addr="{
                  province: form.province,
                  city: form.city,
                  district: form.district,
                }"
              ></addr-select>
            </el-form-item>
            <el-form-item label="省市区" required prop="city">
              <city-select @change="changeCity" :temp="cityList"></city-select>
            </el-form-item>
            <el-form-item label="详细地址" required>
              <el-input
                v-model="form.address"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
             <el-form-item label="经度">
              <el-input v-model="form.longitude" placeholder="经度longitude"></el-input>
            </el-form-item>
             <el-form-item label="纬度" >
              <el-input v-model="form.latitude" placeholder="纬度latitude"></el-input>
            </el-form-item>
            <el-form-item label="介绍">
              <el-input
                type="textarea"
                v-model="form.introduction"
                placeholder="合作商介绍"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍图片">
              <images-uploader
                ref="imgs"
                @getImgUrl="getProductImg"
                :urls="form.introduction_img"
              >
              </images-uploader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="form.id" type="primary" @click="updateForm" :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary"  :loading="loading" @click="addForm">新 增</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
import cooperatorSelect from "../../../components/select/cooperatorSelect.vue";
import { addRule } from "./addRule.js";
import { typeList } from "./type.js";

export default {
  components: {
    citySelect,
    cooperatorSelect,
  },
  data() {
    return {
      typeList:typeList,
      rules: addRule,
      clear: false,
      form: {},
      temp: {},
      cityList: [],
      dialogVisible: false,
      coop_id: "",
      loading:false,
    };
  },

  mounted() {},

  methods: {
    addForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.type == "property") {
            this.form.parent_id = this.coop_id || null;
          }
          this.loading = true
          this.$axios({
            url: "/user/cooperator/insert",
            method: "post",
            data: this.form,
          }).then((res) => {
            this.$notify({
              message: "新增成功",
              duration: 2000,
              type: "success",
            });
            this.dialogVisible = false;
            this.loading = false
            this.$emit("success", 1);
          }).catch(()=>{
            this.loading = false
          })
        }
      });
    },

    updateForm() {
      this.form.parent_id = this.coop_id || 0;
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true
          this.$axios({
            url: "/user/cooperator/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          }).then((res) => {
            this.$notify({
              message: "更新成功",
              duration: 2000,
              type: "success",
            });
            this.dialogVisible = false;
            this.loading = false
            this.$emit("success", 1);
          }).catch(()=>{
            this.loading = false
          })
        }
      });
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
        this.cityList.push(info.province);
        this.cityList.push(info.city);
        this.cityList.push(info.district);
        this.coop_id = info.parent_id;
      } else {
        this.form = {};
        this.temp = {}
        this.cityList = []
        this.coop_id = "";
      }
      this.dialogVisible = true;
      
      this.$refs.imgs.clearAll();
    },
    //选择地址
    changeMapAddr(addr) {
      this.$set(this.form, "province", addr.province);
      this.$set(this.form, "city", addr.city);
      this.$set(this.form, "district", addr.district);
      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
      this.$set(this.form, "address", addr.address);
      this.cityList.push(addr.province);
      this.cityList.push(addr.city);
      this.cityList.push(addr.district);
    },
    changeCity(v) {
      this.$set(this.form, "province", v[0]);
      this.$set(this.form, "city", v[1]);
      this.$set(this.form, "district", v[2]);
      this.cityList = v;
    },
    getProductImg(v) {
      this.form.introduction_img = v;
    },
  },
};
</script>

<style lang="less" scoped>
</style>